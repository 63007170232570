import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Timestamp from '../components/timestamp'

const BlogPost =  ({ data } = {}) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Seo
        title={`${post.frontmatter.title} | Max Polun's blog`}
      />
      <div className='post'>
        <h1>
          <Link to={`/posts/${post.fields.slug}`}>{post.frontmatter.title}</Link>
          <small><Timestamp date={post.frontmatter.date} /></small>
        </h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {
      slug: { eq: $slug }
    })
    {
      html
      frontmatter {
        title
        date
      }
      fields {
        slug
      }
    }
  }
`
